


















import { Component, Vue } from "vue-property-decorator";

import clientModel from "@/api/client.model";
import { translateFieldsClient } from "@/entities/client";
import { formatDayMonthYear } from "@/helpers/utils";
import { translateInsurerPlanRelationKind } from "@/interfaces/corporate_client";
import { translateServicePlanKind } from "@/interfaces/service_plan";
import { translateGender } from "@/interfaces/user";
import EntityCrud from "../entity/EntityCrud.vue";
import EntityForm from "../entity/EntityForm.vue";

@Component({
	components: { EntityCrud, EntityForm },
})
export default class Clients extends Vue {
	formatDayMonthYear = formatDayMonthYear;

	model: any = clientModel;
	creationRoute = "cliente-info/novo";
	title = "Clientes";
	kind = "clientsTable";
	tableColumns = [
		{
			key: "isComplete",
			name: "Cadastro",
			keyAlert: "isComplete",
			guideText: "Cadastro incompleto",
			width: "50px",
			hasSelect: true,
			options: [
				{ name: "Completo", value: true },
				{ name: "Incompleto", value: false },
			],
		},
		{
			key: "isActive",
			kind: "customTag",
			styleTags: {
				true: "primary",
				false: "secondary",
			},
			options: [
				{ name: "Ativo", value: true },
				{ name: "Inativo", value: false },
			],
			hasSelect: true,
			translated: (status: boolean) => {
				return status ? "Sim" : "Não";
			},
			width: "50px",
			isReadOnly: true,
		},
		{ key: "name", kind: "initialsBadges", nameComplement: "lastName", name: "Nome" },
		{ key: "lastName", kind: "invisible" },
		{ key: "corporateClient.name", name: "Cliente Corporativo", keyAlerts: "alerts" },
		{ key: "enterprise.name", name: "Empresa" },
		{
			key: "firstSolutionAppointmentDate",
			name: "Data de início",
			kind: "date",
			onTransform: formatDayMonthYear,
		},
		{
			key: "unfilledProperties",
			name: "Informações Pendentes",
			kind: "array",
			onTranslated: translateFieldsClient,
			orderable: false,
		},
	];

	onRowClick = (id: string) => this.$router.push(`cliente-info/${id}`);

	exportColumns = [
		{ key: "id", name: "Id" },
		{ key: "name", name: "Nome" },
		{ key: "lastName", name: "Sobrenome" },
		{ key: "email" },
		{ key: "secondEmail", name: "E-mail alternativo" },
		{ key: "birthDate", onTransform: formatDayMonthYear },
		{
			key: "corporateClient",
			name: "Cliente Corporativo",
			onTransform: (corporateClient: any) => corporateClient?.name,
		},
		{
			key: "firstSolutionAppointmentDate",
			name: "Data de início",
			onTransform: (firstSolutionAppointmentDate: Date) => formatDayMonthYear(firstSolutionAppointmentDate),
		},
		{ key: "phoneNumber", name: "Telefone com DDD" },
		{ key: "secondPhoneNumber", name: "Telefone com DDD alternativo" },
		{ key: "gender", onTransform: translateGender },
		{ key: "cpf" },
		{
			key: "isActive",
			name: "Está Ativo?",
			onTransform: (status: number) => (status ? "Sim" : "Não"),
		},
		{ key: "insurerNumber" },
		{ key: "address.postCode", name: "Endereço | CEP" },
		{ key: "address.street", name: "Endereço | Rua" },
		{ key: "address.number", name: "Endereço | Número" },
		{ key: "address.complement", name: "Endereço | Complemento" },
		{ key: "address.neighborhood", name: "Endereço | Bairro" },
		{ key: "address.city", name: "Endereço | Cidade" },
		{ key: "address.state", name: "Endereço | Estado" },
		{ key: "isSecondaryAddressMain", name: "Endereço secundário é o principal?" },
		{ key: "secondaryAddress.postCode", name: "Endereço secundário | CEP" },
		{ key: "secondaryAddress.street", name: "Endereço secundário | Rua" },
		{ key: "secondaryAddress.number", name: "Endereço secundário | Número" },
		{ key: "secondaryAddress.complement", name: "Endereço secundário | Complemento" },
		{ key: "secondaryAddress.neighborhood", name: "Endereço secundário | Bairro" },
		{ key: "secondaryAddress.city", name: "Endereço secundário | Cidade" },
		{ key: "secondaryAddress.state", name: "Endereço secundário | Estado" },
		"responsibleNavigationName",
		"responsibleMedicalTeamName",
		"treatmentForm",
		"empathy",
		"serviceDeliveryAddress",
		{ key: "caregiver", name: "Cuidador/Responsável legal" },
		{ key: "contactPhoneNumber", name: "Cuidador | Contato com DDD" },
		{ key: "caregiverBond", name: "Cuidador | Vinculo" },
		{ key: "insurerPlan", name: "Plano", onTransform: (plan: any) => plan?.name },
		{
			key: "insurerPlanRelationKind",
			name: "Plano | Tipo de relação",
			onTransform: translateInsurerPlanRelationKind,
		},
		{ key: "enterprise", name: "Plano | Empresa", onTransform: (enterprise: any) => enterprise?.name },
		{ key: "restriction", name: "Plano | Restrição" },
		{
			key: "homeCare",
			name: "Plano | Atendimento domiciliar",
			onTransform: (homeCare: boolean) => (homeCare ? "Sim" : "Não"),
		},
		{ key: "proceduresCharge", name: "Plano | Pagamento" },
		{ key: "planInfoTreatment", name: "Plano | Forma de tratamento" },
		{
			key: "planInfoDependency",
			name: "Plano | Dependência",
			onTransform: (planInfoDependency: boolean) => (planInfoDependency ? "Sim" : "Não"),
		},
		{
			key: "antecedentDiseases",
			name: "Antecedentes pessoais (CID)",
			relationType: "many-to-many",
			relationToTake: "disease",
			relationPropertyToTake: "description",
		},
		{
			key: "clientTreatments",
			name: "Tratamentos | Nome do produto",
			relationType: "many-to-many",
			relationToTake: "treatment",
			relationPropertyToTake: "productName",
		},
		{
			key: "clientTreatments",
			name: "Tratamentos | Classe terapêutica",
			relationType: "many-to-many",
			relationToTake: "treatment",
			relationPropertyToTake: "therapeuticClass",
		},
		{
			key: "clientTreatments",
			name: "Tratamentos | Substância",
			relationType: "many-to-many",
			relationToTake: "treatment",
			relationPropertyToTake: "substanceName",
		},
		{
			key: "clientTags",
			name: "Tags",
			relationType: "many-to-many",
			relationToTake: "tag",
			relationPropertyToTake: "name",
		},
	];

	formColumns = [
		{ key: "name", name: "Nome", required: true },
		{ key: "lastName", name: "Sobrenome", required: true },
		{ key: "birthDate", readOnly: false, required: true, onTransform: formatDayMonthYear },
		{ key: "gender", onTransform: translateGender },
		{ key: "insurerNumber", kind: "text" },
		{ key: "phoneNumber" },
		{ key: "cpf", required: true },
		{ key: "email" },
		{
			key: "address.postCode",
		},
		{ key: "address.street" },
		{ key: "address.number" },
		{ key: "address.complement" },
		{ key: "address.neighborhood" },
		{ key: "address.city" },
		{
			key: "address.state",
		},
		"responsibleNavigationName",
		"responsibleMedicalTeamName",
		"treatmentForm",
		"empathy",
		{ key: "solutionStartDate", name: "Data de início" },
		{
			key: "isActive",
			inputKind: "selectButtons",
			options: [
				{ name: "Sim", value: true },
				{ name: "Não", value: false },
			],
		},
		{ key: "secondEmail" },
		"secondPhoneNumber",
		"serviceDeliveryAddress",
		"isSecondaryAddressMain",
		{
			key: "secondaryAddress.postCode",
		},
		"secondaryAddress.street",
		"secondaryAddress.number",
		"secondaryAddress.complement",
		"secondaryAddress.neighborhood",
		"secondaryAddress.city",
		"secondaryAddress.state",
		"caregiver",
		"contactPhoneNumber",
		"caregiverBond",
		{ key: "insurerPlan.name", name: "Nome do plano", isReadOnly: true },
		{ key: "insurerPlan.emergencyServiceNumber", isReadOnly: true },
		{
			key: "insurerPlan.servicePlans",
			name: "Serviços",
			kind: "customForm",
			onTransform: this.formatReadOnlyServices,
			isReadOnly: true,
		},
		{
			key: "insurerPlanRelationKind",
			inputKind: "selectButtons",
			onTransform: translateInsurerPlanRelationKind,
		},
		"restriction",
		"homeCare",
		"proceduresCharge",
		{ key: "enterprise.name", name: "Empresa", isReadOnly: true },
	];

	formatReadOnlyServices(services: any[]) {
		if (!services || !services.length) {
			return;
		}
		return services?.reduce((stringServices, currentService, indexService) => {
			return `${stringServices} ${translateServicePlanKind(currentService.serviceKind)}${
				currentService.otherServiceKind ? " - " + currentService.otherServiceKind : ""
			} - ${currentService.description}${indexService !== services.length - 1 && services.length > 1 ? "<br>" : ""}`;
		}, "");
	}
}
