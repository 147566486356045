var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('entity-crud',_vm._b({attrs:{"tableVariant":"secondary"}},'entity-crud',{
		model: _vm.model,
		title: _vm.title,
		tableColumns: _vm.tableColumns,
		creationRoute: _vm.creationRoute,
		onRowClick: _vm.onRowClick,
		kind: _vm.kind,
		formColumns: _vm.formColumns,
		exportColumns: _vm.exportColumns,
		initialSort: { key: 'firstSolutionAppointmentDate', direction: 'DESC' },
	},false))}
var staticRenderFns = []

export { render, staticRenderFns }